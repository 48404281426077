import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addJobListing } from '../../data/jobListings';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './JobOfferForm.css';

function CreateJobOffer() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    kitaId: '',  // This would be populated from user's kitas
    description: '',
    requirements: '',
    benefits: '',
    startDate: '',
    employmentType: 'FULL_TIME',
    salary: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e?.target || { name: null, value: null };
    
    // Handle rich text editor change
    if (name === null && typeof e === 'string') {
      setFormData(prevState => ({
        ...prevState,
        description: e
      }));
      return;
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) newErrors.title = 'Bitte geben Sie einen Titel ein';
    if (!formData.kitaId) newErrors.kitaId = 'Bitte wählen Sie eine Kita aus';
    if (!formData.description.trim()) newErrors.description = 'Bitte geben Sie eine Beschreibung ein';
    if (!formData.requirements.trim()) newErrors.requirements = 'Bitte geben Sie die Anforderungen ein';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      // Add the new job listing
      const selectedKita = userKitas.find(k => k.id === parseInt(formData.kitaId));
      if (!selectedKita) {
        setErrors({ submit: 'Bitte wählen Sie eine gültige Kita aus.' });
        return;
      }

      const newJob = {
        title: formData.title,
        description: formData.description,
        kitaName: selectedKita.name,
        requirements: formData.requirements,
        benefits: formData.benefits,
        employmentType: formData.employmentType,
        startDate: formData.startDate,
        salary: formData.salary,
        contactPerson: formData.contactPerson,
        contactEmail: formData.contactEmail,
        contactPhone: formData.contactPhone
      };
      
      addJobListing(newJob);
      navigate('/employer');
    } catch (error) {
      console.error('Error creating job offer:', error);
      setErrors({ submit: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.' });
    }
  };

  // Dummy data for kita selection
  const userKitas = [
    { id: 1, name: 'Kita Sonnenschein' },
    { id: 2, name: 'Waldorf Kindergarten' }
  ];

  return (
    <div className="page-container">
      <h1>Stellenanzeige bearbeiten</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="job-form">
          <div className="form-group">
            <label htmlFor="title">Stellenbezeichnung *</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="z.B. Erzieher/in in Vollzeit"
            />
            {errors.title && <div className="error-message">{errors.title}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="kitaId">Kita *</label>
            <select
              id="kitaId"
              name="kitaId"
              value={formData.kitaId}
              onChange={handleChange}
            >
              <option value="">Bitte wählen</option>
              {userKitas.map(kita => (
                <option key={kita.id} value={kita.id}>
                  {kita.name}
                </option>
              ))}
            </select>
            {errors.kitaId && <div className="error-message">{errors.kitaId}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="description">Stellenbeschreibung *</label>
            <ReactQuill
              theme="snow"
              value={formData.description}
              onChange={handleChange}
              placeholder="Beschreiben Sie die Stelle..."
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{'list': 'ordered'}, {'list': 'bullet'}],
                  ['link'],
                  ['clean']
                ],
              }}
            />
            {errors.description && <div className="error-message">{errors.description}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="requirements">Anforderungen *</label>
            <textarea
              id="requirements"
              name="requirements"
              value={formData.requirements}
              onChange={handleChange}
              rows="4"
              placeholder="Qualifikationen und Erfahrungen..."
            />
            {errors.requirements && <div className="error-message">{errors.requirements}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="benefits">Wir bieten</label>
            <textarea
              id="benefits"
              name="benefits"
              value={formData.benefits}
              onChange={handleChange}
              rows="4"
              placeholder="Vorteile und Leistungen..."
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="employmentType">Beschäftigungsart</label>
              <select
                id="employmentType"
                name="employmentType"
                value={formData.employmentType}
                onChange={handleChange}
              >
                <option value="FULL_TIME">Vollzeit</option>
                <option value="PART_TIME">Teilzeit</option>
                <option value="TEMPORARY">Befristet</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="startDate">Eintrittsdatum</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="salary">Gehaltsangabe</label>
            <input
              type="text"
              id="salary"
              name="salary"
              value={formData.salary}
              onChange={handleChange}
              placeholder="z.B. 45.000 € - 55.000 € pro Jahr"
            />
          </div>

          <section className="form-section">
            <h2>Kontaktinformationen</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="contactPerson">Ansprechpartner</label>
                <input
                  type="text"
                  id="contactPerson"
                  name="contactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="contactEmail">E-Mail</label>
                <input
                  type="email"
                  id="contactEmail"
                  name="contactEmail"
                  value={formData.contactEmail}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="contactPhone">Telefon</label>
              <input
                type="tel"
                id="contactPhone"
                name="contactPhone"
                value={formData.contactPhone}
                onChange={handleChange}
              />
            </div>
          </section>

          {errors.submit && <div className="error-message">{errors.submit}</div>}
          
          <div className="form-actions">
            <button type="button" className="secondary-button" onClick={() => navigate('/employer')}>
              Abbrechen
            </button>
            <button type="submit" className="primary-button">
              Änderungen speichern
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateJobOffer;
