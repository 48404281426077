import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pencil, Trash2, Eye } from 'lucide-react';
import {deleteJobListing, jobListings, getApplications} from '../../data/jobListings';
import './EmployerDashboard.css';
const dummyKitas = [
  {
    id: 1,
    name: 'Kita Sonnenschein',
    address: 'Sonnenallee 123, 12345 Berlin',
    status: 'active'
  },
  {
    id: 2,
    name: 'Waldorf Kindergarten',
    address: 'Waldweg 45, 12345 Berlin',
    status: 'active'
  }
];


function EmployerDashboard() {
  const navigate = useNavigate();
  const [forceUpdate, setForceUpdate] = useState(false); // Add state to force re-renders

  return (
    <div className="page-container">
      <h1>Arbeitgeber-Dashboard</h1>
      
      <div className="dashboard-container">
        {/* Job Listings Section */}
        <section className="dashboard-section">
          <div className="section-header">
            <h2 className="section-title">Stellenausschreibungen</h2>
            <button 
              className="add-button"
              onClick={() => navigate('/angebot-erstellen')}
            >
              Stelle ausschreiben
            </button>
          </div>

          <div className="items-grid">
            {jobListings.map(job => (
              <div key={job.id} className="dashboard-card">
                <div className="card-info">
                  <h3>{job.title}</h3>
                  <p>{job.kitaName}</p>
                  <span className="status-badge status-active">
                    Aktiv
                  </span>
                  <p>Bewerbungen: {getApplications(job.id).length}</p>
                </div>
                <div className="card-actions">
                  <button 
                    className="action-button"
                    onClick={() => navigate(`/angebot/${job.id}`)}
                  >
                    <Eye size={20} />
                  </button>
                  <button 
                    className="action-button"
                    onClick={() => navigate(`/angebot-bearbeiten/${job.id}`)}
                  >
                    <Pencil size={20} />
                  </button>
                  <button 
                    className="action-button"
                    onClick={() => {
                      if (window.confirm('Möchten Sie diese Stellenanzeige wirklich löschen?')) {
                        deleteJobListing(job.id);
                        // Force a re-render
                        setForceUpdate(prev => !prev);
                      }
                    }}
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Applications Section */}
        <section className="dashboard-section">
          <div className="section-header">
            <h2 className="section-title">Eingegangene Bewerbungen</h2>
          </div>

          <div className="items-grid">
            {jobListings.map(job => {
              const jobApplications = getApplications(job.id);
              return jobApplications.map(application => (
                <div key={application.id} className="dashboard-card">
                  <div className="card-info">
                    <h3>{application.firstName} {application.lastName}</h3>
                    <p>Für: {job.title}</p>
                    <p>Email: {application.email}</p>
                    <span className="status-badge status-active">
                      {application.status}
                    </span>
                    <p>Eingegangen: {new Date(application.submittedAt).toLocaleDateString()}</p>
                  </div>
                  <div className="card-actions">
                    <button 
                      className="action-button"
                      onClick={() => navigate(`/application/${job.id}/view/${application.id}`)}
                    >
                      <Eye size={20} />
                    </button>
                  </div>
                </div>
              ));
            })}
          </div>
        </section>

        {/* Kitas Section */}
        <section className="dashboard-section">
          <div className="section-header">
            <h2 className="section-title">Meine Kitas</h2>
            <button 
              className="add-button"
              onClick={() => navigate('/register-kita')}
            >
              Kita hinzufügen
            </button>
          </div>

          <div className="items-grid">
            {dummyKitas.map(kita => (
              <div key={kita.id} className="dashboard-card">
                <div className="card-info">
                  <h3>{kita.name}</h3>
                  <p>{kita.address}</p>
                  <span className={`status-badge status-${kita.status}`}>
                    {kita.status === 'active' ? 'Aktiv' : 'Inaktiv'}
                  </span>
                </div>
                <div className="card-actions">
                  <button 
                    className="action-button"
                    onClick={() => navigate(`/edit-kita/${kita.id}`)}
                  >
                    <Pencil size={20} />
                  </button>
                  <button className="action-button">
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default EmployerDashboard;
