import React from 'react';
import './JobOffer.css';
import { useParams } from 'react-router-dom';
import { jobListings } from '../../data/jobListings';

const initialData = {
  1: {
    title: "Erzieher/in in Vollzeit",
    kitaName: "Sonnenschein Kita",
    plz: "10115",
    city: "Berlin",
    description: "Wir suchen eine engagierte pädagogische Fachkraft für unsere Krippengruppe. Teamarbeit und liebevoller Umgang mit Kindern sind uns besonders wichtig.",
    requirements: [
      "Abgeschlossene Ausbildung als staatlich anerkannte/r Erzieher/in",
      "Berufserfahrung wünschenswert",
      "Teamfähigkeit und Engagement",
      "Freude an der Arbeit mit Kindern"
    ],
    benefits: [
      "Unbefristeter Arbeitsvertrag",
      "30 Tage Urlaub",
      "Fortbildungsmöglichkeiten",
      "Betriebliche Altersvorsorge"
    ],
    contactPerson: "Frau Schmidt",
    email: "bewerbung@sonnenschein-kita.de",
    phone: "030 123456789"
  },
  2: {
    title: "Pädagogische Fachkraft",
    kitaName: "Regenbogen Kindergarten",
    plz: "10997",
    city: "Berlin",
    description: "Für unsere altersgemischte Gruppe suchen wir Verstärkung. Wir bieten ein tolles Team und moderne Räumlichkeiten.",
    requirements: [
      "Staatliche Anerkennung als Erzieher/in",
      "Erfahrung in der Gruppenleitung",
      "Kreativität und Eigeninitiative"
    ],
    benefits: [
      "Flexible Arbeitszeiten",
      "Moderne Ausstattung",
      "Teamevents",
      "Weiterbildungsbudget"
    ],
    contactPerson: "Herr Meyer",
    email: "karriere@regenbogen-kita.de",
    phone: "030 987654321"
  },
  3: {
    title: "Erzieher/in (m/w/d)",
    kitaName: "Waldorf Kindertagesstätte",
    plz: "10437",
    city: "Berlin",
    description: "Zur Verstärkung unseres Teams suchen wir eine qualifizierte Fachkraft. Waldorf-Erfahrung erwünscht, aber keine Bedingung.",
    requirements: [
      "Staatlich anerkannte/r Erzieher/in",
      "Interesse an Waldorfpädagogik",
      "Musikalische oder künstlerische Fähigkeiten"
    ],
    benefits: [
      "Waldorf-Fortbildungen",
      "Gesundheitsprävention",
      "Bio-Mittagessen",
      "Supervision"
    ],
    contactPerson: "Frau Weber",
    email: "personal@waldorf-kita.de",
    phone: "030 456789012"
  }
};

function JobOffer() {
  const { id } = useParams();
  const job = jobListings.find(job => job.id === parseInt(id));

  if (!job) {
    return (
      <div className="page-container">
        <h1>Stellenangebot nicht gefunden</h1>
      </div>
    );
  }

  // Ensure arrays exist and are actually arrays
  const requirements = Array.isArray(job.requirements) ? job.requirements : [];
  const benefits = Array.isArray(job.benefits) ? job.benefits : [];

  return (
    <div className="page-container">
      <h1>{job.title}</h1>
      <h2>{job.kitaName}</h2>
      <p className="location">{job.plz} {job.city}</p>
      
      <div className="job-container">
        <div className="job-main-content">
          <section className="job-section">
        <h3>Beschreibung</h3>
        <div dangerouslySetInnerHTML={{ __html: job.description }} />
      </section>

      <section className="job-section">
        <h3>Anforderungen</h3>
        <ul>
          {requirements.map((req, index) => (
            <li key={index}>{req}</li>
          ))}
        </ul>
      </section>

      <section className="job-section">
        <h3>Wir bieten</h3>
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>
      </section>
        </div>
        
        <div className="job-sidebar">
          <section className="job-section contact">
            <h3>Kontakt</h3>
            <p>Ansprechpartner: {job.contactPerson}</p>
            <p>Email: {job.email}</p>
            <p>Telefon: {job.phone}</p>
          </section>
          <button 
            className="apply-button"
            onClick={() => window.location.href = `/application?jobId=${id}`}
          >
            Jetzt Bewerben
          </button>
        </div>
      </div>
    </div>
  );
}

export default JobOffer;
