import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './JobOfferForm.css';  // We'll reuse the form styles

function RegisterKita() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    phone: '',
    email: '',
    website: '',
    description: '',
    openingHours: '',
    groupCount: '',
    childrenCount: '',
    pedagogicalConcept: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Bitte geben Sie einen Namen ein';
    if (!formData.street.trim()) newErrors.street = 'Bitte geben Sie eine Straße ein';
    if (!formData.postalCode.trim()) newErrors.postalCode = 'Bitte geben Sie eine PLZ ein';
    if (!formData.city.trim()) newErrors.city = 'Bitte geben Sie eine Stadt ein';
    if (!formData.email.trim()) newErrors.email = 'Bitte geben Sie eine E-Mail-Adresse ein';
    if (!formData.phone.trim()) newErrors.phone = 'Bitte geben Sie eine Telefonnummer ein';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      // Here you would send the data to your backend
      console.log('Registering Kita:', formData);
      
      // Redirect to dashboard after successful registration
      navigate('/employer');
    } catch (error) {
      console.error('Error registering Kita:', error);
      setErrors({ submit: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.' });
    }
  };

  return (
    <div className="page-container">
      <h1>Kita registrieren</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="job-form">
          <div className="form-group">
            <label htmlFor="name">Name der Kita *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="z.B. Kita Sonnenschein"
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>

          <section className="form-section">
            <h2>Adresse</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="street">Straße *</label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  value={formData.street}
                  onChange={handleChange}
                />
                {errors.street && <div className="error-message">{errors.street}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="houseNumber">Hausnummer</label>
                <input
                  type="text"
                  id="houseNumber"
                  name="houseNumber"
                  value={formData.houseNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="postalCode">PLZ *</label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                />
                {errors.postalCode && <div className="error-message">{errors.postalCode}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="city">Stadt *</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
                {errors.city && <div className="error-message">{errors.city}</div>}
              </div>
            </div>
          </section>

          <section className="form-section">
            <h2>Kontaktinformationen</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phone">Telefon *</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && <div className="error-message">{errors.phone}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="email">E-Mail *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="website">Website</label>
              <input
                type="url"
                id="website"
                name="website"
                value={formData.website}
                onChange={handleChange}
                placeholder="z.B. https://www.kita-sonnenschein.de"
              />
            </div>
          </section>

          <section className="form-section">
            <h2>Einrichtungsdetails</h2>
            <div className="form-group">
              <label htmlFor="description">Beschreibung der Einrichtung</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows="4"
                placeholder="Beschreiben Sie Ihre Einrichtung..."
              />
            </div>

            <div className="form-group">
              <label htmlFor="openingHours">Öffnungszeiten</label>
              <textarea
                id="openingHours"
                name="openingHours"
                value={formData.openingHours}
                onChange={handleChange}
                rows="3"
                placeholder="z.B. Mo-Fr: 7:00-17:00 Uhr"
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="groupCount">Anzahl der Gruppen</label>
                <input
                  type="number"
                  id="groupCount"
                  name="groupCount"
                  value={formData.groupCount}
                  onChange={handleChange}
                  min="1"
                />
              </div>
              <div className="form-group">
                <label htmlFor="childrenCount">Anzahl der Plätze</label>
                <input
                  type="number"
                  id="childrenCount"
                  name="childrenCount"
                  value={formData.childrenCount}
                  onChange={handleChange}
                  min="1"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="pedagogicalConcept">Pädagogisches Konzept</label>
              <textarea
                id="pedagogicalConcept"
                name="pedagogicalConcept"
                value={formData.pedagogicalConcept}
                onChange={handleChange}
                rows="4"
                placeholder="Beschreiben Sie Ihr pädagogisches Konzept..."
              />
            </div>
          </section>

          {errors.submit && <div className="error-message">{errors.submit}</div>}
          
          <div className="form-actions">
            <button type="button" className="secondary-button" onClick={() => navigate('/employer')}>
              Abbrechen
            </button>
            <button type="submit" className="primary-button">
              Kita registrieren
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterKita;
