import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { addApplication } from '../../data/jobListings';
import './Application.css';

function Application() {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    birthDate: '',
    earliestStartDate: '',
    salaryExpectation: '',
    coverLetter: '',
    resume: null,
    certificates: null
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: files[0]
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.firstName.trim()) newErrors.firstName = 'Bitte geben Sie Ihren Vornamen ein';
    if (!formData.lastName.trim()) newErrors.lastName = 'Bitte geben Sie Ihren Nachnamen ein';
    if (!formData.email.trim()) newErrors.email = 'Bitte geben Sie Ihre E-Mail-Adresse ein';
    if (!formData.phone.trim()) newErrors.phone = 'Bitte geben Sie Ihre Telefonnummer ein';
    if (!formData.coverLetter.trim()) newErrors.coverLetter = 'Bitte fügen Sie ein Anschreiben hinzu';
    if (!formData.resume) newErrors.resume = 'Bitte fügen Sie Ihren Lebenslauf hinzu';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      // Save the application
      const success = addApplication(parseInt(jobId), formData);
      
      if (success) {
        alert('Ihre Bewerbung wurde erfolgreich eingereicht!');
        // Could add navigation here if desired
      } else {
        throw new Error('Failed to submit application');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      setErrors({ submit: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.' });
    }
  };

  return (
    <div className="page-container">
      <h1>Bewerbung</h1>
      <div className="application-container">
        <form onSubmit={handleSubmit} className="application-form">
          <section className="form-section">
            <h2>Persönliche Informationen</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">Vorname *</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Nachname *</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && <div className="error-message">{errors.lastName}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">E-Mail *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="phone">Telefon *</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && <div className="error-message">{errors.phone}</div>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="birthDate">Geburtsdatum</label>
                <input
                  type="date"
                  id="birthDate"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                />
              </div>
            </div>
          </section>

          <section className="form-section">
            <h2>Adresse</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="street">Straße</label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  value={formData.street}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="houseNumber">Hausnummer</label>
                <input
                  type="text"
                  id="houseNumber"
                  name="houseNumber"
                  value={formData.houseNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="postalCode">PLZ</label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">Stadt</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
            </div>
          </section>

          <section className="form-section">
            <h2>Bewerbungsdetails</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="earliestStartDate">Frühester Eintrittstermin</label>
                <input
                  type="date"
                  id="earliestStartDate"
                  name="earliestStartDate"
                  value={formData.earliestStartDate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="salaryExpectation">Gehaltsvorstellung (brutto/Jahr)</label>
                <input
                  type="number"
                  id="salaryExpectation"
                  name="salaryExpectation"
                  value={formData.salaryExpectation}
                  onChange={handleChange}
                  placeholder="z.B. 45000"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="coverLetter">Anschreiben *</label>
              <textarea
                id="coverLetter"
                name="coverLetter"
                value={formData.coverLetter}
                onChange={handleChange}
                placeholder="Ihr Anschreiben hier..."
              />
              {errors.coverLetter && <div className="error-message">{errors.coverLetter}</div>}
            </div>
          </section>

          <section className="form-section">
            <h2>Dokumente</h2>
            <div className="form-group">
              <div className="file-input-container">
                <label className="file-input-label" htmlFor="resume">
                  Lebenslauf hochladen *
                </label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  className="file-input"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                />
              </div>
              {formData.resume && (
                <div className="selected-file">
                  Ausgewählte Datei: {formData.resume.name}
                </div>
              )}
              {errors.resume && <div className="error-message">{errors.resume}</div>}
            </div>

            <div className="form-group">
              <div className="file-input-container">
                <label className="file-input-label" htmlFor="certificates">
                  Zeugnisse hochladen
                </label>
                <input
                  type="file"
                  id="certificates"
                  name="certificates"
                  className="file-input"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                  multiple
                />
              </div>
              {formData.certificates && (
                <div className="selected-file">
                  Ausgewählte Datei: {formData.certificates.name}
                </div>
              )}
            </div>
          </section>

          {errors.submit && <div className="error-message">{errors.submit}</div>}
          
          <button type="submit" className="submit-button">
            Bewerbung absenden
          </button>
        </form>
      </div>
    </div>
  );
}

export default Application;
