import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SearchStart from './pages/Search/SearchStart';
import SearchPage from './pages/Search/SearchPage';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import JobOffer from './pages/JobOffer/JobOffer';
import Application from './pages/Application/Application';
import ApplicationView from './pages/Application/ApplicationView';
import EmployerDashboard from './pages/Employer/EmployerDashboard';
import RegisterKita from './pages/Employer/RegisterKita';
import EditKita from './pages/Employer/EditKita';
import CreateJobOffer from './pages/Employer/CreateJobOffer';
import EditJobOffer from './pages/Employer/EditJobOffer';
import TopBar from './components/TopBar/TopBar';

function App() {
  return (
    <Router>
      <div>
        <TopBar />
        <Routes>
          <Route path="/" element={<SearchStart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/angebot/:id" element={<JobOffer />} />
          <Route path="/application" element={<Application />} />
          <Route path="/application/:jobId/view/:applicationId" element={<ApplicationView />} />
          <Route path="/employer" element={<EmployerDashboard />} />
          <Route path="/register-kita" element={<RegisterKita />} />
          <Route path="/edit-kita/:id" element={<EditKita />} />
          <Route path="/angebot-erstellen" element={<CreateJobOffer />} />
          <Route path="/angebot-bearbeiten/:id" element={<EditJobOffer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
