import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { jobListings, updateJobListing } from '../../data/jobListings';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './JobOfferForm.css';

function EditJobOffer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    kitaName: '',
    description: '',
    requirements: [],
    benefits: [],
    startDate: '',
    employmentType: 'FULL_TIME',
    salary: '',
    contactPerson: '',
    email: '',
    phone: '',
    plz: '',
    city: ''
  });

  useEffect(() => {
    // Find the job in jobListings using the id
    const job = jobListings.find(job => job.id === parseInt(id));
    if (job) {
      setFormData({
        ...job,
        requirements: Array.isArray(job.requirements) ? job.requirements : [],
        benefits: Array.isArray(job.benefits) ? job.benefits : []
      });
    }
  }, [id]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e?.target || { name: null, value: null };
    
    // Handle rich text editor change
    if (name === null && typeof e === 'string') {
      setFormData(prevState => ({
        ...prevState,
        description: e
      }));
      return;
    }

    if (name === 'requirements' || name === 'benefits') {
      // Split the textarea input by newlines to create arrays
      const arrayValue = value.split('\n').filter(item => item.trim() !== '');
      setFormData(prevState => ({
        ...prevState,
        [name]: arrayValue
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) newErrors.title = 'Bitte geben Sie einen Titel ein';
    if (!formData.kitaId) newErrors.kitaId = 'Bitte wählen Sie eine Kita aus';
    if (!formData.description.trim()) newErrors.description = 'Bitte geben Sie eine Beschreibung ein';
    if (!formData.requirements.length) newErrors.requirements = 'Bitte geben Sie mindestens eine Anforderung ein';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      const success = updateJobListing(parseInt(id), formData);
      
      if (success) {
        navigate('/employer');
      } else {
        setErrors({ submit: 'Stellenanzeige konnte nicht gefunden werden.' });
      }
    } catch (error) {
      console.error('Error updating job offer:', error);
      setErrors({ submit: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.' });
    }
  };

  // Dummy data for kita selection
  const userKitas = [
    { id: 1, name: 'Kita Sonnenschein' },
    { id: 2, name: 'Waldorf Kindergarten' }
  ];

  return (
    <div className="page-container">
      <h1>Stellenanzeige bearbeiten</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit} className="job-form">
          <div className="form-group">
            <label htmlFor="title">Stellenbezeichnung *</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="z.B. Erzieher/in in Vollzeit"
            />
            {errors.title && <div className="error-message">{errors.title}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="kitaName">Kita Name *</label>
            <input
              type="text"
              id="kitaName"
              name="kitaName"
              value={formData.kitaName}
              onChange={handleChange}
              placeholder="Name der Kita"
            />
            {errors.kitaName && <div className="error-message">{errors.kitaName}</div>}
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="plz">PLZ</label>
              <input
                type="text"
                id="plz"
                name="plz"
                value={formData.plz}
                onChange={handleChange}
                placeholder="PLZ"
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">Stadt</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="Stadt"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="description">Stellenbeschreibung *</label>
            <ReactQuill
              theme="snow"
              value={formData.description}
              onChange={handleChange}
              placeholder="Beschreiben Sie die Stelle..."
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{'list': 'ordered'}, {'list': 'bullet'}],
                  ['link'],
                  ['clean']
                ],
              }}
            />
            {errors.description && <div className="error-message">{errors.description}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="requirements">Anforderungen *</label>
            <textarea
              id="requirements"
              name="requirements"
              value={formData.requirements.join('\n')}
              onChange={handleChange}
              rows="4"
              placeholder="Eine Anforderung pro Zeile..."
            />
            {errors.requirements && <div className="error-message">{errors.requirements}</div>}
          </div>

          <div className="form-group">
            <label htmlFor="benefits">Wir bieten</label>
            <textarea
              id="benefits"
              name="benefits"
              value={formData.benefits.join('\n')}
              onChange={handleChange}
              rows="4"
              placeholder="Ein Vorteil pro Zeile..."
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="employmentType">Beschäftigungsart</label>
              <select
                id="employmentType"
                name="employmentType"
                value={formData.employmentType}
                onChange={handleChange}
              >
                <option value="FULL_TIME">Vollzeit</option>
                <option value="PART_TIME">Teilzeit</option>
                <option value="TEMPORARY">Befristet</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="startDate">Eintrittsdatum</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="salary">Gehaltsangabe</label>
            <input
              type="text"
              id="salary"
              name="salary"
              value={formData.salary}
              onChange={handleChange}
              placeholder="z.B. 45.000 € - 55.000 € pro Jahr"
            />
          </div>

          <section className="form-section">
            <h2>Kontaktinformationen</h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="contactPerson">Ansprechpartner</label>
                <input
                  type="text"
                  id="contactPerson"
                  name="contactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">E-Mail</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="phone">Telefon</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </section>

          {errors.submit && <div className="error-message">{errors.submit}</div>}
          
          <div className="form-actions">
            <button type="button" className="secondary-button" onClick={() => navigate('/employer')}>
              Abbrechen
            </button>
            <button type="submit" className="primary-button">
              Änderungen speichern
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditJobOffer;
