import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import './TopBar.css';

function TopBar() {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
        }
    };

    return (
        <div className="topbar">
            <div className="topbar-left">
                <Link to="/" className="logo">Kita Jobs</Link>
            </div>
            
            <div className="topbar-center">
                <form onSubmit={handleSubmit} className="search-container">
                    <input
                        type="text"
                        placeholder="Wo möchtest du arbeiten?"
                        className="search-input"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button type="submit" className="topbar-search-button">
                        <Search className="search-icon" />
                    </button>
                </form>
            </div>
            
            <div className="topbar-right">
                <Link to="/employer" className="nav-link">für Arbeitgeber</Link>
                <Link to="/login" className="nav-link">Login</Link>
            </div>
        </div>
    );
}

export default TopBar;
