import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jobListings, getApplications } from '../../data/jobListings';
import './Application.css';

function ApplicationView() {
  const { jobId, applicationId } = useParams();
  const navigate = useNavigate();
  
  // Find the job and application
  const job = jobListings.find(j => j.id === parseInt(jobId));
  const application = getApplications(parseInt(jobId))
    .find(a => a.id === parseInt(applicationId));

  if (!job || !application) {
    return <div className="page-container">
      <h1>Bewerbung nicht gefunden</h1>
      <button onClick={() => navigate('/dashboard')} className="submit-button">
        Zurück zum Dashboard
      </button>
    </div>;
  }

  return (
    <div className="page-container">
      <h1>Bewerbung Ansicht</h1>
      <div className="application-container">
        <div className="application-view">
          <section className="view-section">
            <h2>Stelle</h2>
            <p><strong>{job.title}</strong> bei {job.kitaName}</p>
            <p>Status: <span className="status-badge">{application.status}</span></p>
            <p>Eingegangen: {new Date(application.submittedAt).toLocaleDateString()}</p>
          </section>

          <section className="view-section">
            <h2>Persönliche Informationen</h2>
            <p><strong>Name:</strong> {application.firstName} {application.lastName}</p>
            <p><strong>E-Mail:</strong> {application.email}</p>
            <p><strong>Telefon:</strong> {application.phone}</p>
            {application.birthDate && (
              <p><strong>Geburtsdatum:</strong> {application.birthDate}</p>
            )}
          </section>

          {(application.street || application.city) && (
            <section className="view-section">
              <h2>Adresse</h2>
              {application.street && application.houseNumber && (
                <p>{application.street} {application.houseNumber}</p>
              )}
              {application.postalCode && application.city && (
                <p>{application.postalCode} {application.city}</p>
              )}
            </section>
          )}

          <section className="view-section">
            <h2>Bewerbungsdetails</h2>
            {application.earliestStartDate && (
              <p><strong>Frühester Eintrittstermin:</strong> {application.earliestStartDate}</p>
            )}
            {application.salaryExpectation && (
              <p><strong>Gehaltsvorstellung:</strong> {application.salaryExpectation}€/Jahr</p>
            )}
            <div className="cover-letter">
              <h3>Anschreiben</h3>
              <p>{application.coverLetter}</p>
            </div>
          </section>

          <section className="view-section">
            <h2>Dokumente</h2>
            {application.resume && (
              <p><strong>Lebenslauf:</strong> {application.resume.name}</p>
            )}
            {application.certificates && (
              <p><strong>Zeugnisse:</strong> {application.certificates.name}</p>
            )}
          </section>

          <div className="button-group">
            <button onClick={() => navigate('/dashboard')} className="submit-button">
              Zurück zum Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationView;
