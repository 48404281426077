// SearchStart.jsx
import React, { useState } from 'react';
import { Search, MapPin, Building2, Users } from 'lucide-react';
import "./SearchStart.css"

function SearchStart() {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            window.location.href = `/search?q=${encodeURIComponent(searchTerm)}`;
        }
    }

    return (
        <div className="start-page">
            <div className="hero-section">
                <div className="content-wrapper">
                    <h1 className="hero-title">
                        Finde deinen Traumjob<br/>in der Kinderbetreuung
                    </h1>
                    <p className="hero-subtitle">
                        Über 1.000 Kitas suchen nach engagierten Fachkräften wie dir
                    </p>

                    <form onSubmit={handleSubmit} className="search-container">
                        <div className="search-wrapper">
                            <MapPin className="location-icon" />
                            <input
                                type="text"
                                placeholder="Wo möchtest du arbeiten?"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="search-input"
                            />
                            <button type="submit" className="hero-search-button">
                                <Search className="search-icon"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="features-section">
                <div className="feature-card">
                    <Building2 size={32} />
                    <h3>Vielfältige Einrichtungen</h3>
                    <p>Von kleinen Kitas bis zu großen Bildungszentren</p>
                </div>
                <div className="feature-card">
                    <Users size={32} />
                    <h3>Persönliche Betreuung</h3>
                    <p>Direkter Kontakt zu deinem künftigen Arbeitgeber</p>
                </div>
                <div className="feature-card">
                    <Search size={32} />
                    <h3>Einfache Suche</h3>
                    <p>Finde genau die Stelle, die zu dir passt</p>
                </div>
            </div>
        </div>
    );
}

export default SearchStart;
