import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Here you would typically make an API call to your backend
      // For now, we'll just simulate a login with dummy credentials
      if (formData.email === 'test@example.com' && formData.password === 'password123') {
        // Successful login
        navigate('/employer'); // Redirect to employer dashboard
      } else {
        setError('Ungültige Email oder Passwort');
      }
    } catch (err) {
      setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <div className="page-container">
      <h1>Login</h1>
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Passwort</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          {error && <div className="error-message">{error}</div>}
          
          <button type="submit" className="login-button">
            Einloggen
          </button>
        </form>

        <div className="forgot-password">
          <a href="/forgot-password">Passwort vergessen?</a>
        </div>
        <div className="register-link">
          Noch kein Konto? <a href="/register">Jetzt registrieren</a>
        </div>
      </div>
    </div>
  );
}

export default Login;
