import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { jobListings } from '../../data/jobListings';
import './SearchPage.css';

function SearchPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');

  return (
    <div className="page-container">
      <h1>Suchergebnisse für: {query}</h1>
      <div className="search-results">
        {jobListings.map(job => (
          <div 
            key={job.id} 
            className="job-card" 
            onClick={() => window.location.href = `/angebot/${job.id}`}
          >
            <div className="job-title">{job.title}</div>
            <div className="kita-name">{job.kitaName}</div>
            <div className="location">{job.plz} {job.city}</div>
            <div className="description" dangerouslySetInnerHTML={{ __html: job.description }} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SearchPage;
