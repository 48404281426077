// Load job listings from localStorage or use initial data if none exists
const initialData = [
  {
    id: 1,
    title: "Erzieher/in in Vollzeit",
    kitaName: "Sonnenschein Kita",
    plz: "10115",
    city: "Berlin",
    description: "Wir suchen eine engagierte pädagogische Fachkraft für unsere Krippengruppe. Teamarbeit und liebevoller Umgang mit Kindern sind uns besonders wichtig.",
    requirements: [
      "Abgeschlossene Ausbildung als staatlich anerkannte/r Erzieher/in",
      "Berufserfahrung wünschenswert",
      "Teamfähigkeit und Engagement",
      "Freude an der Arbeit mit Kindern"
    ],
    benefits: [
      "Unbefristeter Arbeitsvertrag",
      "30 Tage Urlaub",
      "Fortbildungsmöglichkeiten",
      "Betriebliche Altersvorsorge"
    ],
    contactPerson: "Frau Schmidt",
    email: "bewerbung@sonnenschein-kita.de",
    phone: "030 123456789"
  },
  {
    id: 2,
    title: "Pädagogische Fachkraft",
    kitaName: "Regenbogen Kindergarten",
    plz: "10997",
    city: "Berlin",
    description: "Für unsere altersgemischte Gruppe suchen wir Verstärkung. Wir bieten ein tolles Team und moderne Räumlichkeiten.",
    requirements: [
      "Staatliche Anerkennung als Erzieher/in",
      "Erfahrung in der Gruppenleitung",
      "Kreativität und Eigeninitiative"
    ],
    benefits: [
      "Flexible Arbeitszeiten",
      "Moderne Ausstattung",
      "Teamevents",
      "Weiterbildungsbudget"
    ],
    contactPerson: "Herr Meyer",
    email: "karriere@regenbogen-kita.de",
    phone: "030 987654321"
  },
  {
    id: 3,
    title: "Erzieher/in (m/w/d)",
    kitaName: "Waldorf Kindertagesstätte",
    plz: "10437",
    city: "Berlin",
    description: "Zur Verstärkung unseres Teams suchen wir eine qualifizierte Fachkraft. Waldorf-Erfahrung erwünscht, aber keine Bedingung.",
    requirements: [
      "Staatlich anerkannte/r Erzieher/in",
      "Interesse an Waldorfpädagogik",
      "Musikalische oder künstlerische Fähigkeiten"
    ],
    benefits: [
      "Waldorf-Fortbildungen",
      "Gesundheitsprävention",
      "Bio-Mittagessen",
      "Supervision"
    ],
    contactPerson: "Frau Weber",
    email: "personal@waldorf-kita.de",
    phone: "030 456789012"
  }
];

// Initialize applications storage
const initialApplications = {};

export const jobListings = JSON.parse(localStorage.getItem('jobListings')) || initialData;
export const applications = JSON.parse(localStorage.getItem('applications')) || initialApplications;

// Save data to localStorage
const saveToStorage = () => {
  localStorage.setItem('jobListings', JSON.stringify(jobListings));
  localStorage.setItem('applications', JSON.stringify(applications));
};

// Function to add new application
export const addApplication = (jobId, applicationData) => {
  if (!applications[jobId]) {
    applications[jobId] = [];
  }
  applications[jobId].push({
    ...applicationData,
    id: Date.now(),
    status: 'Neu',
    submittedAt: new Date().toISOString()
  });
  saveToStorage();
  return true;
};

// Function to get applications for a job
export const getApplications = (jobId) => {
  return applications[jobId] || [];
};

// Function to add new job listing
export const addJobListing = (newJob) => {
  const job = {
    ...newJob,
    id: jobListings.length + 1,
    plz: "10115", // Default values for demo
    city: "Berlin",
    // Ensure required fields are present
    title: newJob.title || "Neue Stelle",
    description: newJob.description || "",
    kitaName: newJob.kitaName || "Unbekannte Kita"
  };
  jobListings.push(job);
  saveToStorage();
  console.log("New job added:", job); // For debugging
  return job;
};

// Function to update a job listing
export const updateJobListing = (jobId, updatedJob) => {
  const index = jobListings.findIndex(job => job.id === jobId);
  if (index !== -1) {
    jobListings[index] = { ...jobListings[index], ...updatedJob };
    saveToStorage();
    return true;
  }
  return false;
};

// Function to delete a job listing
export const deleteJobListing = (jobId) => {
  const index = jobListings.findIndex(job => job.id === jobId);
  if (index !== -1) {
    jobListings.splice(index, 1);
    saveToStorage();
    return true;
  }
  return false;
};
